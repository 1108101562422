<template>
  <div class="container">
    <div class="columns">
      <div class="column">
        <div class="card">
          <div class="card-content">
            <div style="width: 100%"><iframe width="100%" height="600" frameborder="0" scrolling="no" marginheight="0"
                marginwidth="0"
                src="https://maps.google.com/maps?width=100%25&amp;height=600&amp;hl=en&amp;q=Kavaklar%20Cd.%20No:15+(Toray%20Makine)&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"><a
                  href="https://www.gps.ie/">gps systems</a></iframe></div>
          </div>
        </div>
      </div>
      <div class="column">
        <div class="card">
          <div class="card-title">{{ $t('map.slogan') }}</div>
          <div class="card-content">
            <p>
              <br />Toray Makine Ambalaj Sanayi
              <br />
              <br />Orta Mah. Kavaklar Cad. No: 15 No: 103, Adapazarı/Sakarya
              <br />
              <br />Tel: 0546 736 50 98
              <br />
              <br />
              <a href="mailto: toraymakine@gmail.com">toraymakine@gmail.com</a>
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      w: 600
    };
  },
  mounted: function () {
    if (
      //
      /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent
      )
    ) {
      this.w = 330;
    }
  }
};
</script>

<style scoped>
.container {
  padding-bottom: 150px;
  padding-top: 60px;
}

.card-title {
  font-size: 20px;
  padding-top: 80px;
  font-weight: bold;
}
</style>
