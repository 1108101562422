<template>
  <div class="container about-card">
    <div class="card">
      <div class="card-content">
        <p>
          {{$t('about_card.paragraph1')}}
          <br />
          <br />
          {{$t('about_card.paragraph2')}}
          <br />
          <br />
          {{$t('about_card.paragraph3')}}
          <br />
          <br />
          <br />
          <b>{{ $t('about_card.subslogan') }}</b>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "AboutCard"
};
</script>

<style scoped>
div {
  margin-top: 30px;
  margin-bottom: 200px;
}
p {
  font-weight: 600;
}
</style>
