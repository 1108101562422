<template>
  <p>Not Found</p>
</template>

<script>
export default {

}
</script>

<style scoped>
.p {
    font-size: 40px;
}

</style>