<template>
  <div>
    <AboutCard />
  </div>
</template>

<script>
import AboutCard from "../components/AboutCard";
export default {
  name: "About",
  title: "Toray Makine - Hakkimizda",
  components: {
    AboutCard
  }
};
</script>

<style scoped></style>
