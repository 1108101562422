<template>
  <div class="container">
    <div class="columns">
      <div class="column">
        <ProductCard
          :title="$t('product_card.title.product1')"
          image="sd-500.png"
          :showfooter="false"
          @click.native="showModal"
        />
      </div>
      <div class="column">
        <div class="card has-text-centered">
          <header class="card-header">
            <p class="card-header-title">
            {{$t('sd500.general_title')}}
            </p>
          </header>
          <div class="card-content">
            <div class="content">
              <ul>
                <li>{{$t('sd500.general_item1')}}</li>
                <li>{{$t('sd500.general_item2')}}</li>
                <li>{{$t('sd500.general_item3')}}</li>
                <li>{{$t('sd500.general_item4')}}</li>
                <li>{{$t('sd500.general_item5')}}</li>
                <li>{{$t('sd500.general_item6')}}</li>
                <li>{{$t('sd500.general_item7')}}</li>
                <li>{{$t('sd500.general_item8')}}</li>
                <li>{{$t('sd500.general_item9')}}</li>
                <li>{{$t('sd500.general_item10')}}</li>
                <li>{{$t('sd500.general_item11')}}</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div class="column">
        <div class="card has-text-centered">
          <header class="card-header">
            <p class="card-header-title">
              {{$t('sd500.technical_title')}}
            </p>
          </header>
          <div class="card-content">
            <div class="content">
              <ul>
                <li>{{$t('sd500.technical_item1')}}</li>
                <li>{{$t('sd500.technical_item2')}}</li>
                <li>{{$t('sd500.technical_item3')}}</li>
                <li>{{$t('sd500.technical_item4')}}</li>
                <li>{{$t('sd500.technical_item5')}}</li>
                <li>{{$t('sd500.technical_item6')}}</li>
                <li>{{$t('sd500.technical_item7')}}</li>
                <li>{{$t('sd500.technical_item8')}}</li>
                <li>{{$t('sd500.technical_item9')}}</li>
                <li>{{$t('sd500.technical_item10')}}</li>
                <li>{{$t('sd500.technical_item11')}}</li>
                <li>{{$t('sd500.technical_item12')}}</li>
                <li>{{$t('sd500.technical_item13')}}</li>
                <li>{{$t('sd500.technical_item14')}}</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
    <LightBox
      v-if="isDisplaying"
      :media="media"
      v-on:onClosed="showModal"
    ></LightBox>
  </div>
</template>

<script>
import ProductCard from "../components/ProductCard";
import LightBox from "vue-image-lightbox";
import image from "../assets/sd-500.png";

export default {
  title: "Toray Makine - SD500",
  data() {
    return {
      isDisplaying: false,
      media: [
        {
          src: image,
          caption: "caption"
        }
      ]
    };
  },
  name: "SD500",
  components: {
    ProductCard,
    LightBox
  },
  methods: {
    showModal: function() {
      this.isDisplaying = !this.isDisplaying;
    }
  }
};
</script>

<style>
.container {
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 10px;
}

.card.has-text-centered .card-header,
.card.has-text-centered .card-content,
.card.has-text-centered .card-footer {
  justify-content: center;
  align-items: center;
}
ul {
  text-align: left;
}
li {
  line-height: 2.5;
}
</style>
