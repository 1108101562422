<template>
  <div>
    <Map></Map>
  </div>
</template>

<script>
import Map from "../components/Map";

export default {
  title: "Toray Makine - Iletisim",
  name: "Contact",
  components: {
    Map
  }
};
</script>

<style></style>
