<template>
  <div>
    <ProductPreview />
  </div>
</template>

<script>
import ProductPreview from "../components/ProductPreview";

export default {
  title: "Toray Makine - Urunler",
  name: "Products",
  components: {
    ProductPreview
  }
};
</script>
